import './styles/globals.css'

import { QueryClientProvider } from '@tanstack/react-query'
import { APIProvider } from '@vis.gl/react-google-maps'
import { RouterProvider } from 'react-router-dom'
import { Toaster } from 'sonner'

import { ThemeProvider } from './components/ThemeProvider'
import { env } from './env'
import { queryClient } from './lib/react-query'
import { router } from './routes'

export default function App() {
  return (
    <APIProvider apiKey={env.VITE_GOOGLE_MAPS_API}>
      <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
        <Toaster
          position="bottom-right"
          toastOptions={{
            style: {
              boxShadow: 'none',
              width: '500px',
              right: '0',
            },
          }}
        />
      </ThemeProvider>
    </APIProvider>
  )
}
