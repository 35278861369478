import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { endOfDay, format, parseISO, startOfDay } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import _ from 'lodash'
import { Calendar, Maximize, Minimize, Search } from 'lucide-react'
import { ReactNode, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSearchParams } from 'react-router-dom'
import {
  Area,
  AreaChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts'
import { Props } from 'recharts/types/component/DefaultLegendContent'
import { z } from 'zod'

import { getIncidents } from '../../api/get-incidents'
import { getIncidentsPerCityMetric } from '../../api/get-incidents-per-city-metric'
import { getIncidentsPerLevelMetric } from '../../api/get-incidents-per-level-metric'
import { getRoles } from '../../api/get-roles'
import { Button } from '../../components/Button'
import { ChartTooltip } from '../../components/ChartTooltip'
import { DateRange } from '../../components/Form/DatePicker/Content'
import * as DatePicker from '../../components/Form/DatePicker/index'
import * as Input from '../../components/Form/Input'
import { Pagination } from '../../components/Pagination'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../components/Table'
import { Tag } from '../../components/Tag'
import { useTheme } from '../../components/ThemeProvider'
import { ThemeToggle } from '../../components/ThemeToggle'
import { Tooltip as TooltipComponent } from '../../components/Tooltip'
import { firstLetterToUppercase } from '../../lib/upperCase'
import { colors } from '../../styles/theme'
import { CreateIncidentModal } from './create-incident-modal'
import { DetailsIncidentModal } from './details-incident-modal'
import { EditIncidentModal } from './edit-incident-modal'
import { IncidentsPerCitySkeleton } from './incidents-per-city-skeleton'
import { IncidentsPerLevelSkeleton } from './incidents-per-level-skeleton'
import { IncidentsTableSkeleton } from './incidents-table-skeleton'

export function Incidents() {
  const [searchParams, setSearchParams] = useSearchParams()

  const levelsData = [
    {
      level: 1,
      name: '0-32',
      amount: 0,
      color: '#333741',
    },
    {
      level: 2,
      name: '32-64',
      amount: 0,
      color: '#6941C6',
    },
    {
      level: 3,
      name: '64-256',
      amount: 0,
      color: '#7F56D9',
    },
    {
      level: 4,
      name: '256-500',
      amount: 0,
      color: '#9E77ED',
    },
    {
      level: 5,
      name: '500+',
      amount: 0,
      color: '#B692F6',
    },
  ]

  const { theme } = useTheme()

  if (theme === 'light') {
    levelsData[0].color = '#EAECF0'
  }

  const perPage = z.coerce.number().parse(searchParams.get('amount') ?? '10')

  const pageIndex = z.coerce
    .number()
    .transform((page) => page - 1)
    .parse(searchParams.get('page') ?? '1')

  const title = searchParams.get('title')
  const fullscreen = searchParams.get('fullscreen') === 'true'

  const [date, setDate] = useState<DateRange>({
    from: startOfDay(new Date()),
    to: endOfDay(new Date()),
  })

  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles,
  })

  const hasPermissionsToManageIncidents = roles?.teams.some((team) =>
    team.permissions.some((permission) => permission.name === 'incidents'),
  )

  const { data: incidentsResult, isLoading: isLoadingIncidents } = useQuery({
    queryKey: ['incidents', perPage, pageIndex, date, title],
    queryFn: () =>
      getIncidents({
        perPage,
        pageIndex,
        from: date.from,
        to: date.to,
        title,
      }),
  })

  const {
    data: incidentsPerLevelResult,
    isLoading: isLoadingIncidentsPerLevel,
  } = useQuery({
    queryKey: ['incidents-per-level-metric', date],
    queryFn: () =>
      getIncidentsPerLevelMetric({
        from: date.from,
        to: date.to,
      }),
  })

  const { data: incidentsPerCityResult, isLoading: isLoadingIncidentsPerCity } =
    useQuery({
      queryKey: ['incidents-per-city-metric', date],
      queryFn: () =>
        getIncidentsPerCityMetric({
          from: date.from,
          to: date.to,
        }),
    })

  if (incidentsPerLevelResult) {
    levelsData.forEach((level) => (level.amount = 0))

    for (const incidentPerLevel of incidentsPerLevelResult.incidentsPerLevel) {
      const level = levelsData.find(
        ({ level }) => level === incidentPerLevel.level,
      )

      if (level) {
        level.amount = incidentPerLevel.count
      }
    }
  }

  const renderLegend = ({ payload }: Props): ReactNode => {
    if (!payload) return

    return (
      <div className="absolute right-2 flex flex-col-reverse">
        {payload.map((entry, index) => (
          <div
            key={`item-${index}`}
            className={clsx(
              'inline-flex items-center gap-2 text-sm text-gray-600 dark:text-gray-150',
              {
                'mt-1': index !== payload.length - 1,
              },
            )}
          >
            <div
              className="h-2 w-2 rounded-full"
              style={{ background: entry.color }}
            />
            {entry.value}
          </div>
        ))}
      </div>
    )
  }

  function handleToggleFullscreen() {
    if (!document.fullscreenElement) {
      setSearchParams((state) => {
        state.set('fullscreen', 'true')

        return state
      })
      document.documentElement.requestFullscreen()
    } else if (document.exitFullscreen) {
      setSearchParams((state) => {
        state.delete('fullscreen')

        return state
      })
      document.exitFullscreen()
    }
  }

  function handlePaginate(pageIndex: number) {
    setSearchParams((state) => {
      state.set('page', (pageIndex + 1).toString())

      return state
    })
  }

  return (
    <>
      <Helmet>
        <title>HUB | Incidentes</title>
      </Helmet>
      <div className="mx-4 md:mx-0 md:flex md:items-start">
        <div>
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-gray-25">
            Incidentes na rede
          </h1>
          <p className="mt-1 text-gray-600 dark:text-gray-375">
            Resumo de indisponibilidades na rede
          </p>
        </div>
        <div className="ml-auto flex gap-3">
          {!fullscreen && <ThemeToggle />}
          <Button
            onClick={handleToggleFullscreen}
            variant="ghost"
            className="hidden md:block"
          >
            {fullscreen ? (
              <Minimize className="h-5 w-5" />
            ) : (
              <Maximize className="h-5 w-5" />
            )}
          </Button>
          {hasPermissionsToManageIncidents && !fullscreen && (
            <CreateIncidentModal />
          )}
        </div>
      </div>
      <div className="mx-4 my-8 md:mx-0 md:flex md:gap-6">
        <div className="w-full rounded-xl border border-gray-100 px-4 py-5 dark:border-gray-750 md:max-w-sm md:p-6">
          <h1 className="font-semibold text-gray-900 dark:text-gray-25">
            Níveis de incidente
          </h1>
          <p className="text-sm text-gray-600 dark:text-gray-150">
            Média de clientes por nível
          </p>
          {incidentsPerLevelResult && (
            <ResponsiveContainer height={240}>
              <PieChart>
                <Pie
                  data={levelsData}
                  cx="30%"
                  cy="40%"
                  labelLine={false}
                  outerRadius={90}
                  innerRadius={50}
                  dataKey="amount"
                >
                  {levelsData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.color}
                      stroke={entry.color}
                    />
                  ))}
                </Pie>
                <Legend
                  content={renderLegend}
                  verticalAlign="top"
                  height={36}
                />
                <Tooltip
                  content={<ChartTooltip />}
                  cursor={{ fill: 'transparent' }}
                />
              </PieChart>
            </ResponsiveContainer>
          )}
          {isLoadingIncidentsPerLevel && <IncidentsPerLevelSkeleton />}
        </div>
        <div className="mt-6 w-full rounded-xl border border-gray-100 px-4 py-5 dark:border-gray-750 md:mt-0 md:p-6">
          <h2 className="font-semibold text-gray-900 dark:text-gray-25">
            Ocorrências por cidade
          </h2>
          <p className="text-sm text-gray-600 dark:text-gray-150">
            Acompanhe a quantidade de ocorrências de cada cidade
          </p>
          {incidentsPerCityResult && (
            <ResponsiveContainer width="100%" height={240}>
              <AreaChart
                margin={{ top: 20, bottom: 10 }}
                style={{ fontSize: 12 }}
                data={incidentsPerCityResult?.incidentsPerCity.map((item) => {
                  return {
                    city: item.city === 'all' ? 'Todas' : item.city,
                    count: item.count,
                  }
                })}
              >
                <defs>
                  <linearGradient
                    id="customGradient"
                    x1="1"
                    y1="1"
                    x2="0"
                    y2="0"
                  >
                    <stop offset="0%" stopColor="#B692F61A" stopOpacity={0} />
                    <stop offset="100%" stopColor="#B692F61A" stopOpacity={1} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="city"
                  axisLine={false}
                  tickLine={false}
                  dy={16}
                  interval="preserveStartEnd"
                />

                <CartesianGrid
                  vertical={false}
                  className="stroke-gray-80 dark:stroke-gray-750"
                />
                <Area
                  dataKey="count"
                  stroke={colors['purple-400']}
                  strokeWidth={2}
                  fill="url(#customGradient)"
                />
                <Tooltip
                  content={<ChartTooltip />}
                  cursor={{ fill: 'transparent' }}
                />
              </AreaChart>
            </ResponsiveContainer>
          )}
          {isLoadingIncidentsPerCity && <IncidentsPerCitySkeleton />}
        </div>
      </div>
      <div className="mt-8">
        <div className="mx-4 md:mx-0 md:flex">
          <div>
            <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-25">
              Incidentes
            </h1>
            <p className="text-sm text-gray-600 dark:text-gray-150">
              Rastreie os incidentes na rede
            </p>
          </div>
          {!fullscreen && (
            <div className="mt-4 flex flex-col gap-3 md:ml-auto md:mt-0 md:flex-row-reverse md:items-start">
              <Input.Root>
                <Input.Prefix>
                  <Search className="h-5 w-5" />
                </Input.Prefix>
                <Input.Control
                  placeholder="Buscar"
                  onBlur={(e) => {
                    setSearchParams((state) => {
                      if (e.currentTarget.value) {
                        state.set('title', e.currentTarget.value)
                      } else {
                        state.delete('title')
                      }

                      return state
                    })
                  }}
                />
              </Input.Root>
              <DatePicker.Root>
                <DatePicker.Trigger>
                  <Button
                    variant="outline"
                    className="flex items-center justify-center gap-1.5"
                  >
                    <Calendar className="h-5 w-5" />
                    {firstLetterToUppercase(
                      format(date.from ?? new Date(), 'MMM dd, yyyy', {
                        locale: ptBR,
                      }),
                    )}{' '}
                    -{' '}
                    {firstLetterToUppercase(
                      format(date.to ?? new Date(), 'MMM dd, yyyy', {
                        locale: ptBR,
                      }),
                    )}
                  </Button>
                </DatePicker.Trigger>
                <DatePicker.Portal>
                  <DatePicker.Content
                    side="left"
                    align="center"
                    max={new Date()}
                    state={date}
                    setState={setDate}
                    handlePaginate={handlePaginate}
                  />
                </DatePicker.Portal>
              </DatePicker.Root>
            </div>
          )}
        </div>
        <Table className="mt-6">
          <TableHeader>
            <TableHead>Incidente</TableHead>
            <TableHead>Nível</TableHead>
            <TableHead>Cidade</TableHead>
            <TableHead>Criado por</TableHead>
            <TableHead>Hora</TableHead>
            <TableHead>Prazo</TableHead>
            <TableHead>Categoria</TableHead>
            <TableHead>Status</TableHead>
            <TableHead />
          </TableHeader>
          <TableBody>
            {incidentsResult &&
              incidentsResult.incidents.map((incident) => {
                return (
                  <TableRow key={incident.id}>
                    <TableCell className="font-medium text-gray-900 dark:font-normal dark:text-gray-25">
                      {incident.title}
                    </TableCell>
                    <TableCell>
                      <TooltipComponent
                        sideOffset={8}
                        description={`Nível ${incident.level}: ${
                          levelsData[incident.level - 1].name
                        }`}
                        asChild
                      >
                        <div className="flex w-fit items-center gap-2">
                          {Array.from({ length: incident.level }).map(
                            (_, index) => (
                              <div
                                key={index}
                                className="h-2 w-2 rounded-full"
                                style={{
                                  background: levelsData[index].color,
                                }}
                              />
                            ),
                          )}
                        </div>
                      </TooltipComponent>
                    </TableCell>
                    <TableCell>
                      <Tag>
                        {incident.city === 'all' ? 'Todas' : incident.city}
                      </Tag>
                    </TableCell>
                    <TableCell>{incident.createdBy.name}</TableCell>
                    <TableCell>
                      {format(
                        parseISO(incident.created_at),
                        'HH:mm:ss dd-MM-yyyy',
                      )}
                    </TableCell>
                    <TableCell>
                      <Tag>{incident.term || 'N/A'}</Tag>
                    </TableCell>
                    <TableCell>
                      <Tag>{incident.category.name}</Tag>
                    </TableCell>
                    <TableCell>
                      <Tag
                        className="flex items-center"
                        variant={
                          incident.status.type === 'maintenance' ||
                          incident.status.type === 'finished'
                            ? 'green'
                            : incident.status.type === 'displacement'
                              ? 'yellow'
                              : incident.status.type === 'onSite'
                                ? 'blue'
                                : 'red'
                        }
                      >
                        <div
                          className={clsx(
                            'mr-1 h-1.5 w-1.5 animate-pulse rounded-full',
                            {
                              'bg-red-300': incident.status.type === 'incident',
                              'bg-green-300':
                                incident.status.type === 'maintenance' ||
                                incident.status.type === 'finished',
                              'bg-yellow-300':
                                incident.status.type === 'displacement',
                              'bg-blue-300': incident.status.type === 'onSite',
                            },
                          )}
                        />
                        {incident.status.name}
                      </Tag>
                    </TableCell>

                    <TableCell>
                      {!fullscreen && (
                        <div className="flex justify-end gap-3">
                          {hasPermissionsToManageIncidents && (
                            <EditIncidentModal incident={incident} />
                          )}
                          <DetailsIncidentModal incident={incident} />
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            {isLoadingIncidents && <IncidentsTableSkeleton perPage={perPage} />}
          </TableBody>
        </Table>
        {_.isEmpty(incidentsResult?.incidents) && !isLoadingIncidents && (
          <div className="flex w-full flex-col items-center justify-center border-x border-b border-gray-100 px-8 py-10 pb-12 dark:border-gray-750">
            <div className="rounded-lg border border-gray-100 p-3 shadow-xs dark:border-gray-750">
              <Search className="h-6 w-6 text-gray-700 dark:text-gray-100" />
            </div>
            <h1 className="mt-4 font-semibold text-gray-900 dark:text-gray-25">
              Nenhum incidente encontrado
            </h1>
            <p className="mt-1 text-center text-sm text-gray-600 dark:text-gray-375">
              Sua pesquisa não encontrou nenhum incidente. <br />
            </p>
          </div>
        )}
        {incidentsResult && !fullscreen && (
          <Pagination
            onPageChange={handlePaginate}
            pageIndex={incidentsResult.meta.pageIndex}
            perPage={incidentsResult.meta.perPage}
            totalCount={incidentsResult.meta.totalCount}
            totalInPage={incidentsResult.incidents.length}
          />
        )}
      </div>
    </>
  )
}
