import { api } from '../lib/axios'

interface GetUsersQuery {
  pageIndex?: number | null
  perPage?: number | null
  active?: boolean | null
}

export interface GetUsersResponse {
  users: {
    id: string
    name: string
    avatarUrl: string
    active: boolean
    firstAccess: boolean
    email: string
    team: {
      id: string
      name: string
      color: 'purple' | 'green' | 'blue' | 'yellow' | 'red' | 'gray'
    }[]
  }[]
  meta: {
    pageIndex: number
    perPage: number
    totalCount: number
  }
}

export async function getUsers({ pageIndex, perPage, active }: GetUsersQuery) {
  const response = await api.get<GetUsersResponse>('/users', {
    params: {
      pageIndex,
      perPage,
      active,
    },
  })

  return response.data
}
