import { api } from '../lib/axios'

interface GetTicketsLocationPerCityQuery {
  city: string | null
  statusId?: string | null
  categoryId?: string | null
}

export interface GetTicketsLocationPerCityResponse {
  tickets: {
    id: string
    title: string
    coordinates: string
    status: {
      id: string
      name: string
      type: string
    }
  }[]
  status: {
    id: string
    name: string
  }[]
  categories: {
    id: string
    name: string
  }[]
}

export async function getTicketsLocationPerCity({
  city,
  statusId,
  categoryId,
}: GetTicketsLocationPerCityQuery) {
  const response = await api.get<GetTicketsLocationPerCityResponse>(
    '/tickets/location',
    {
      params: {
        city,
        statusId,
        categoryId,
      },
    },
  )

  return response.data
}
