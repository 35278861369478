import { useEffect, useState } from 'react'

function useGoogleMaps() {
  const [loaded, setLoaded] = useState(!!window.google.maps.Map)

  useEffect(() => {
    if (window.google.maps.Map) {
      setLoaded(true)
    } else {
      const interval = setInterval(() => {
        if (window.google.maps.Map) {
          setLoaded(true)
          clearInterval(interval)
        }
      }, 100)
      return () => clearInterval(interval)
    }
  }, [])

  return loaded
}

export default useGoogleMaps
