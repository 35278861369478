import { useQuery } from '@tanstack/react-query'
import {
  AdvancedMarker,
  InfoWindow,
  Map,
  useAdvancedMarkerRef,
  useMap,
} from '@vis.gl/react-google-maps'
import clsx from 'clsx'
import { LocateFixed, Pin, Search } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'

import {
  getTicketsLocationPerCity,
  GetTicketsLocationPerCityResponse,
} from '../../../api/get-tickets-location-per-city'
import { Button } from '../../../components/Button'
import { Checkbox } from '../../../components/Form/Checkbox'
import * as Input from '../../../components/Form/Input'
import * as Select from '../../../components/Form/Select'
import { Tag } from '../../../components/Tag'
import useGoogleMaps from '../../../hooks/useGoogleMaps'
import { cities } from '../my/create-ticket-modal'

export function TicketsMap() {
  const [location, setLocation] = useState<{
    lat: number
    lng: number
  }>()

  const [searchParams, setSearchParams] = useSearchParams()

  const selectedCity = searchParams.get('city')
  const status = searchParams.get('status')
  const category = searchParams.get('category')
  const coordinates = searchParams.get('coordinates')

  const { data: result } = useQuery({
    queryKey: ['get-tickets-location-per-city', selectedCity, status, category],
    queryFn: () =>
      getTicketsLocationPerCity({
        city: selectedCity || null,
        statusId: status || null,
        categoryId: category || null,
      }),
  })

  const isLoaded = useGoogleMaps()

  const map = useMap('ticketsMap')

  useEffect(() => {
    if (coordinates) {
      setLocation({
        lat: Number(coordinates.split(',')[0]),
        lng: Number(coordinates.split(',')[1]),
      })
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        setLocation({
          lat: coords.latitude,
          lng: coords.longitude,
        })
      })
    }
  }, [navigator])

  useEffect(() => {
    if (coordinates) {
      setLocation({
        lat: Number(coordinates.split(',')[0]),
        lng: Number(coordinates.split(',')[1]),
      })
    }
  }, [coordinates])

  useEffect(() => {
    if (location) {
      map?.panTo({ lat: location.lat, lng: location.lng })
    }
  }, [location])

  async function handleMarkCityOnMap(name: string) {
    if (!map) return

    const geocoder = new window.google.maps.Geocoder()

    geocoder.geocode(
      {
        address:
          name === 'Cruzeiro'
            ? 'Cruzeiro, SP'
            : name === 'Virgínia'
              ? 'Virgínia, MG'
              : name,
      },
      (results, status) => {
        if (status === 'OK' && results && results[0].geometry.location) {
          const { lat, lng } = results[0].geometry.location
          setLocation({ lat: lat(), lng: lng() })
          map?.panTo({ lat: lat(), lng: lng() })
          map.setZoom(15)
        }
      },
    )
  }

  return (
    <div className="flex">
      <div className="flex min-h-screen w-full max-w-md flex-col gap-1 border-r border-gray-100 px-6 pb-4 pt-8 dark:border-gray-750">
        <div className="flex flex-col gap-1.5">
          <label className="dark:text-gray-125">Buscar</label>
          <Input.Root>
            <Input.Prefix>
              <Search className="h-5 w-5 text-gray-300 dark:text-gray-375" />
            </Input.Prefix>
            <Input.Control
              defaultValue={coordinates || undefined}
              onBlur={(e) => {
                setSearchParams((state) => {
                  const regex = /^(-?\d+\.\d+),\s*(-?\d+\.\d+)$/

                  if (e.currentTarget.value.match(regex)) {
                    state.set('coordinates', e.currentTarget.value)
                  } else {
                    state.delete('coordinates')
                  }

                  return state
                })
              }}
              placeholder="Coordenadas"
            />
          </Input.Root>
        </div>
        {selectedCity && (
          <>
            <div className="mt-4 flex flex-col gap-1.5">
              <label className="dark:text-gray-125">Status</label>
              <Select.Root
                defaultValue="all"
                onValueChange={(value) => {
                  setSearchParams((state) => {
                    if (value && value !== 'all') {
                      state.set('status', value)
                    } else {
                      state.delete('status')
                    }

                    return state
                  })
                }}
              >
                <Select.Trigger className="flex-1 dark:bg-gray-950">
                  <Select.Value placeholder="Selecione o Status" />
                </Select.Trigger>
                <Select.Content>
                  <Select.Item value="all">
                    <Select.ItemText>Todas</Select.ItemText>
                  </Select.Item>
                  {result &&
                    result.status.map((status) => (
                      <Select.Item key={status.id} value={status.id}>
                        <Select.ItemText>{status.name}</Select.ItemText>
                      </Select.Item>
                    ))}
                </Select.Content>
              </Select.Root>
            </div>
            <div className="mt-4 flex flex-col gap-1.5">
              <label className="dark:text-gray-125">Categoria</label>
              <Select.Root
                defaultValue="all"
                onValueChange={(value) => {
                  setSearchParams((state) => {
                    if (value && value !== 'all') {
                      state.set('category', value)
                    } else {
                      state.delete('category')
                    }

                    return state
                  })
                }}
              >
                <Select.Trigger className="flex-1 dark:bg-gray-950">
                  <Select.Value placeholder="Selecione a categoria" />
                </Select.Trigger>
                <Select.Content>
                  <Select.Item value="all">
                    <Select.ItemText>Todas</Select.ItemText>
                  </Select.Item>
                  {result &&
                    result.categories.map((category) => (
                      <Select.Item key={category.id} value={category.id}>
                        <Select.ItemText>{category.name}</Select.ItemText>
                      </Select.Item>
                    ))}
                </Select.Content>
              </Select.Root>
            </div>
          </>
        )}
        <main className="mt-8">
          <h1 className="text-base font-medium text-gray-700 dark:text-gray-125">
            Chamados
          </h1>
          <div className="mt-4 space-y-2">
            {cities.map((city) => (
              <div key={city} className="flex items-center gap-3">
                <Checkbox
                  id={city}
                  checked={selectedCity === city}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      setSearchParams((state) => {
                        state.set('city', city)

                        return state
                      })
                    } else {
                      setSearchParams((state) => {
                        state.delete('city')

                        return state
                      })
                    }
                  }}
                />
                <label
                  htmlFor={city}
                  className="font-semibold text-gray-700 dark:text-gray-125"
                >
                  {city}
                </label>
                <Button
                  onClick={() => handleMarkCityOnMap(city)}
                  variant="ghost"
                  className="ml-auto p-2"
                >
                  <LocateFixed className="h-6 w-6" />
                </Button>
              </div>
            ))}
          </div>
        </main>
      </div>
      <div className="flex-1">
        {isLoaded && location && (
          <Map
            id="ticketsMap"
            mapId={'5643212'}
            defaultCenter={location}
            defaultZoom={15}
            gestureHandling={'greedy'}
          >
            {result &&
              result.tickets.map((ticket) => {
                const [lat, lng] = ticket.coordinates.split(',')

                return (
                  <MarkerWithInfoWindow
                    key={ticket.id}
                    ticket={ticket}
                    position={{
                      lat: Number(lat),
                      lng: Number(lng),
                    }}
                  />
                )
              })}
          </Map>
        )}
      </div>
    </div>
  )
}

const MarkerWithInfoWindow = ({
  position,
  ticket,
}: {
  position: { lat: number; lng: number }
  ticket: GetTicketsLocationPerCityResponse['tickets'][0]
}) => {
  const [markerRef, marker] = useAdvancedMarkerRef()

  const [infoWindowShown, setInfoWindowShown] = useState(false)

  const handleMarkerClick = useCallback(
    () => setInfoWindowShown((isShown) => !isShown),
    [],
  )

  const handleClose = useCallback(() => setInfoWindowShown(false), [])

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        position={position}
        onClick={handleMarkerClick}
      />

      {infoWindowShown && (
        <InfoWindow
          anchor={marker}
          onClose={handleClose}
          pixelOffset={[0, -2]}
          headerContent={
            <>
              <div className="flex items-center gap-2">
                <Tag className="!border-purple-200 !bg-purple-50 !text-purple-700">
                  RMX-{ticket.id}
                </Tag>
                <Tag
                  className={clsx({
                    '!border-green-200 !bg-green-50 !text-green-700':
                      ticket.status.type === 'finished',
                    '!border-yellow-200 !bg-yellow-50 !text-yellow-700':
                      ticket.status.type === 'wait',
                    '!border-purple-200 !bg-purple-50 !text-purple-700':
                      ticket.status.type === 'analysis',
                  })}
                >
                  {ticket.status.name}
                </Tag>
              </div>
              <h1 className="mb-4 mt-2 text-lg font-semibold text-gray-800">
                {ticket.title}
              </h1>
            </>
          }
        >
          <div className="mb-2 flex flex-col gap-4">
            <p className="inline-flex items-center gap-1 text-sm font-medium text-gray-900">
              <Pin className="h-4 w-4 text-gray-600" strokeWidth={3} />
              {ticket.coordinates}
            </p>
            <span>
              Clique{' '}
              <Link
                to={`/tickets/${ticket.id}/overview`}
                className="font-medium text-gray-700"
              >
                <span className="text-purple-700 underline">aqui</span>{' '}
              </Link>
              para visualizar o chamado
            </span>
          </div>
        </InfoWindow>
      )}
    </>
  )
}
