import { api } from '../lib/axios'

export interface GetUsersOnTeamQuery {
  id?: string | null
  pageIndex?: number | null
  perPage?: number | null
}

export interface GetUsersOnTeamResponse {
  users: {
    id: string
    name: string
    email: string
    active: boolean
    isLeader: boolean
  }[]
  meta: {
    pageIndex: number
    perPage: number
    totalCount: number
  }
}

export async function getUsersOnTeam({
  id,
  pageIndex,
  perPage,
}: GetUsersOnTeamQuery) {
  const response = await api.get<GetUsersOnTeamResponse>(
    `/teams/${id}/users-on-team`,
    {
      params: {
        pageIndex,
        perPage,
      },
    },
  )

  return response.data
}
