import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import { format, isBefore, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import _ from 'lodash'
import { Plus, Search } from 'lucide-react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { z } from 'zod'

import { getAuthenticatedUser } from '../../../api/get-authenticated-user'
import { getTickets } from '../../../api/get-tickets'
import { Button } from '../../../components/Button'
import { Pagination } from '../../../components/Pagination'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/Table'
import { Tag } from '../../../components/Tag'
import { Tooltip } from '../../../components/Tooltip'
import { CreateTicketModal } from './create-ticket-modal'
import { TicketsTableFilters } from './tickets-table-filters'
import { TicketsTableSkeleton } from './tickets-table-skeleton'

export function My() {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const ticketId = searchParams.get('ticketId')
  const status = searchParams.get('status') ?? 'open'
  const category = searchParams.get('category')
  const priority = searchParams.get('priority')
  const title = searchParams.get('title')
  const location = searchParams.get('location')
  const responsable = searchParams.get('responsable')
  const teamId = searchParams.get('teamId')
  const sort = searchParams.get('sort')
  const key = searchParams.get('key')
  const perPage = z.coerce.number().parse(searchParams.get('amount') ?? '10')

  const pageIndex = z.coerce
    .number()
    .transform((page) => page - 1)
    .parse(searchParams.get('page') ?? '1')

  const { data: authenticatedUser } = useQuery({
    queryKey: ['authenticated-user'],
    queryFn: getAuthenticatedUser,
  })

  const { data: result, isLoading } = useQuery({
    queryKey: [
      'tickets',
      pageIndex,
      perPage,
      ticketId,
      status,
      category,
      priority,
      title,
      location,
      responsable,
      teamId,
      sort,
      key,
    ],
    queryFn: () =>
      getTickets({
        pageIndex,
        perPage,
        ticketId,
        status,
        category,
        priority,
        title,
        location,
        responsable,
        teamId,
        sort,
        key,
      }),
  })

  function handlePaginate(pageIndex: number) {
    setSearchParams((state) => {
      state.set('page', (pageIndex + 1).toString())

      return state
    })
  }

  return (
    <>
      <div className="mx-4 mt-8 md:mx-0 md:mt-0 md:flex">
        <div className="flex flex-1 flex-col md:flex-row">
          <div>
            <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-25">
              Seus chamados
            </h1>
            <p className="mt-1 text-sm text-gray-600 dark:text-gray-375">
              Gerencie e crie seus chamados.
            </p>
          </div>
          <div className="mt-4 flex items-start gap-3 md:ml-auto md:mt-0">
            <CreateTicketModal>
              <Button className="flex items-center gap-1.5">
                <Plus className="h-5 w-5" />
                Criar chamado
              </Button>
            </CreateTicketModal>
          </div>
        </div>
      </div>

      <TicketsTableFilters />

      <Table className="mt-6 table-fixed md:mt-0">
        <TableHeader>
          <TableHead name="ticketId" className="w-[120px]">
            Ticket
          </TableHead>
          <TableHead name="title" className="w-[500px]">
            Título
          </TableHead>
          <TableHead name="date" className="w-[130px]">
            Data
          </TableHead>
          <TableHead name="location" className="w-[180px]">
            Cidade
          </TableHead>
          <TableHead name="responsable" className="w-[180px]">
            Responsável
          </TableHead>
          <TableHead name="team" className="w-[180px]">
            Equipe
          </TableHead>
          <TableHead name="category" className="w-[220px]">
            Categoria
          </TableHead>
          <TableHead name="priority" className="w-[120px]">
            Prioridade
          </TableHead>
          <TableHead name="status" className="w-[180px]">
            Status
          </TableHead>
        </TableHeader>
        <TableBody>
          {result &&
            result.tickets?.map((ticket) => {
              const userViewedAnytime = ticket.viewedBy.find(
                (view) => view.userId === authenticatedUser?.user.id,
              )

              const viewedLastUpdate = userViewedAnytime
                ? isBefore(
                    parseISO(ticket.updated_at),
                    parseISO(userViewedAnytime.updated_at),
                  )
                : false

              return (
                <TableRow
                  key={ticket.id}
                  className="cursor-pointer"
                  onMouseDown={async (e) => {
                    if (e.button === 1) {
                      window.open(`/tickets/${ticket.id}/overview`)
                    } else {
                      navigate(`/tickets/${ticket.id}/overview`)
                    }
                  }}
                >
                  <TableCell className="truncate">
                    <Tag>RMX-{ticket.id}</Tag>
                  </TableCell>
                  <TableCell
                    className={clsx('truncate', {
                      'font-medium text-gray-900 dark:text-gray-25':
                        !viewedLastUpdate,
                    })}
                  >
                    <Tooltip description={ticket.title} align="start" asChild>
                      <p className="truncate">{ticket.title}</p>
                    </Tooltip>
                  </TableCell>
                  <TableCell className="truncate">
                    <Tooltip
                      description={format(
                        new Date(ticket.created_at),
                        'HH:mm:ss dd/MM/yyyy',
                      )}
                    >
                      {format(new Date(ticket.created_at), "MMM d',' u", {
                        locale: ptBR,
                      })}
                    </Tooltip>
                  </TableCell>
                  <TableCell className="truncate">
                    {ticket.location === 'all' ? (
                      <Tag>Todas</Tag>
                    ) : ticket.location ? (
                      <Tag>{ticket.location}</Tag>
                    ) : (
                      <Tag>Nenhuma</Tag>
                    )}
                  </TableCell>
                  <TableCell className="truncate">
                    <Tooltip description={ticket.responsable.name}>
                      {ticket.responsable.name}
                    </Tooltip>
                  </TableCell>
                  <TableCell className="truncate">
                    <Tag variant={ticket.team.color}>{ticket.team.name}</Tag>
                  </TableCell>
                  <TableCell>
                    <Tag>{ticket.category.name}</Tag>
                  </TableCell>
                  <TableCell className="truncate">
                    {
                      <Tag
                        variant={
                          ticket.priority === 'high'
                            ? 'red'
                            : ticket.priority === 'medium'
                              ? 'yellow'
                              : 'blue'
                        }
                      >
                        {ticket.priority === 'high'
                          ? 'Alta'
                          : ticket.priority === 'medium'
                            ? 'Média'
                            : 'Baixa'}
                      </Tag>
                    }
                  </TableCell>
                  <TableCell className="truncate">
                    {
                      <Tag
                        variant={
                          ticket.status.type === 'wait'
                            ? 'yellow'
                            : ticket.status.type === 'analysis'
                              ? 'purple'
                              : 'green'
                        }
                      >
                        {ticket.status.name}
                      </Tag>
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          {isLoading && <TicketsTableSkeleton perPage={perPage} />}
        </TableBody>
      </Table>
      {_.isEmpty(result?.tickets) && !isLoading && (
        <div className="flex w-full flex-col items-center justify-center border-x border-b border-gray-100 px-8 py-10 pb-12 dark:border-gray-750">
          <div className="rounded-lg border border-gray-100 p-3 shadow-xs dark:border-gray-750">
            <Search className="h-6 w-6 text-gray-700 dark:text-gray-100" />
          </div>
          <h1 className="mt-4 font-semibold text-gray-900 dark:text-gray-25">
            Nenhum chamado encontrado
          </h1>
          <p className="mt-1 text-center text-sm text-gray-600 dark:text-gray-375">
            Sua pesquisa não encontrou nenhum chamado. <br />
            Tente novamente ou crie um novo chamado.
          </p>
          <div className="mt-6 flex gap-3">
            <Button variant="outline">Limpar busca</Button>
            <CreateTicketModal>
              <Button variant="primary" className="flex gap-1.5">
                <Plus className="h-5 w-5" /> Novo chamado
              </Button>
            </CreateTicketModal>
          </div>
        </div>
      )}
      {result && (
        <Pagination
          onPageChange={handlePaginate}
          pageIndex={result.meta.pageIndex}
          totalCount={result.meta.totalCount}
          totalInPage={result.tickets.length}
          perPage={result.meta.perPage}
        />
      )}
    </>
  )
}
