import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-alert-dialog'
import { useMutation, useQuery } from '@tanstack/react-query'
import { PackagePlus, X } from 'lucide-react'
import { ReactNode, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { getUsers } from '../../../api/get-users'
import { registerStock } from '../../../api/register-stock'
import { Button } from '../../../components/Button'
import * as Combobox from '../../../components/Form/Combobox'
import * as Input from '../../../components/Form/Input'
import { Toast } from '../../../components/Toast'
import { Tooltip } from '../../../components/Tooltip'
import { abbreviateName } from '../../../lib/abbreviate'
import { queryClient } from '../../../lib/react-query'

interface CreateStockModalProps {
  children: ReactNode
}

export const createStockSchema = z.object({
  name: z.string().min(3),
  members: z
    .array(
      z.object({
        id: z.string().uuid(),
        name: z.string(),
        email: z.string().email(),
      }),
    )
    .optional(),
})

export type createStockProps = z.infer<typeof createStockSchema>

export function CreateStockModal({ children }: CreateStockModalProps) {
  const [open, setOpen] = useState<boolean>()

  const {
    reset,
    watch,
    resetField,
    setValue,
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<createStockProps>({
    resolver: zodResolver(createStockSchema),
  })

  const { mutateAsync: registerStockFn } = useMutation({
    mutationKey: ['register-stock'],
    mutationFn: registerStock,
    onSuccess() {
      queryClient.refetchQueries({ queryKey: ['get-stock'] })
      queryClient.refetchQueries({ queryKey: ['layout-settings-count'] })
      setOpen(false)
      reset({
        name: '',
        members: [],
      })
      toast.custom((t) => (
        <Toast t={t} title="Estoque criado com sucesso!" variant="success" />
      ))
    },
    onError: () => {
      setOpen(false)
      reset()
      toast.custom((t) => (
        <Toast
          t={t}
          title="Ocorreu um erro ao tentar criar o estoque, tente novamente mais tarde."
          variant="error"
        />
      ))
    },
  })

  function handleCreateStock(data: createStockProps) {
    const { name, members } = data

    registerStockFn({ name, members })
  }

  const { data: result } = useQuery({
    queryKey: ['users'],
    queryFn: () => getUsers({ perPage: 9999, pageIndex: 0, active: true }),
  })

  const members = watch('members')

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(value) => {
        if (!value) {
          reset({
            name: '',
            members: [],
          })
        }
        setOpen(value)
      }}
    >
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed top-0 min-h-screen w-full bg-gray-950 opacity-70 backdrop-blur dark:bg-gray-775" />
        <Dialog.Content className="fixed left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white p-6 shadow-xl data-[state=open]:animate-contentShow dark:bg-gray-950">
          <div className="flex justify-between">
            <div className="rounded-full bg-purple-600 p-3">
              <PackagePlus className="h-6 w-6 text-gray-100" />
            </div>
            <Dialog.Cancel className="self-start rounded-lg p-2 hover:bg-gray-50 focus:shadow-none dark:hover:bg-gray-750">
              <X className="h-6 w-6 text-gray-400 dark:text-gray-150" />
            </Dialog.Cancel>
          </div>
          <Dialog.Title className="mt-4 text-lg font-semibold text-gray-900 dark:text-gray-25">
            Criar Estoque
          </Dialog.Title>
          <Dialog.Description className="mt-1 text-gray-600 dark:text-gray-375">
            Preencha as informações abaixo para gerar um novo estoque
          </Dialog.Description>
          <form className="mt-5" onSubmit={handleSubmit(handleCreateStock)}>
            <div className="flex flex-col gap-1.5">
              <label
                htmlFor="name"
                className="text-sm font-medium text-gray-700 dark:text-gray-125"
              >
                Nome*
              </label>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input.Root>
                    <Input.Control {...field} placeholder="Almoxarifado DGW" />
                  </Input.Root>
                )}
              />
            </div>
            <div className="mt-4 flex flex-col gap-1.5">
              <label
                htmlFor="email"
                className="text-sm font-medium text-gray-700 dark:text-gray-125"
              >
                Usuários*
              </label>
              <Controller
                name="members"
                control={control}
                render={({ field }) => (
                  <Combobox.Root {...field} multiple>
                    <Combobox.Trigger placeholder="Selecione os usuários" />

                    <Combobox.Content>
                      {result &&
                        result.users.map((user) => {
                          return (
                            <Combobox.Option key={user.id} value={user}>
                              {user.name}
                            </Combobox.Option>
                          )
                        })}
                    </Combobox.Content>
                  </Combobox.Root>
                )}
              />
              <div className="mt-2 flex flex-wrap">
                {members
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map((member) => (
                    <Tooltip key={member.id} description={member.name} asChild>
                      <div
                        onClick={() => {
                          const array = members.filter(
                            (user) => user.id !== member.id,
                          )

                          if (array.length === 0) {
                            resetField('members')
                          } else {
                            setValue('members', array)
                          }
                        }}
                        className="!first:ml-0 -ml-1 flex h-10 w-10 cursor-pointer select-none items-center justify-center rounded-full border border-white bg-purple-600 text-sm font-semibold text-white transition-colors hover:bg-purple-700 dark:border-gray-950"
                      >
                        {abbreviateName(member.name)}
                      </div>
                    </Tooltip>
                  ))}
              </div>
            </div>

            <div className="mt-8 flex gap-3">
              <Dialog.Cancel asChild>
                <Button variant="outline" className="flex-1">
                  Cancelar
                </Button>
              </Dialog.Cancel>
              <Button
                type="submit"
                className="flex-1"
                variant="primary"
                disabled={!isValid}
              >
                Criar
              </Button>
            </div>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
