import { useMutation, useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import {
  ChartSpline,
  Cog,
  DoorOpen,
  FileBox,
  Layers,
  Map,
  Package,
  Search,
  ServerOff,
  Webhook,
} from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getAuthenticatedUser } from '../../api/get-authenticated-user'
import { getRoles } from '../../api/get-roles'
import { signOut } from '../../api/sign-out'
import { Google } from '../../assets/google'
import { IXC } from '../../assets/ixc'
import { Raimax } from '../../assets/raimax'
import { Avatar } from '../../components/Avatar'
import * as Input from '../../components/Form/Input'
import { Skeleton } from '../../components/Skeleton'
import { Tooltip } from '../../components/Tooltip'
import { abbreviateName } from '../../lib/abbreviate'
import { queryClient } from '../../lib/react-query'
import { Item, ItemProps } from './sidebar-item'

export function SideBar() {
  const [minWidth, maxWidth, defaultWidth] = [80, 312, 312]
  const [width, setWidth] = useState(() => {
    // avoid server-side error
    if (typeof window !== 'undefined') {
      return (
        parseInt(String(localStorage.getItem('sidebarWidth'))) || defaultWidth
      )
    } else {
      return defaultWidth
    }
  })

  const [query, setQuery] = useState('')

  const isResized = useRef(false)

  const breakPoint = width >= 210

  const navigate = useNavigate()

  useEffect(() => {
    window.addEventListener('mousemove', (e) => {
      if (!isResized.current) {
        return
      }

      setWidth((previousWidth) => {
        const newWidth = previousWidth + e.movementX

        const isWidthInRange = newWidth >= minWidth && newWidth <= maxWidth

        return isWidthInRange ? newWidth : previousWidth
      })
    })

    window.addEventListener('mouseup', () => {
      isResized.current = false
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    localStorage.setItem('sidebarWidth', String(width))

    if (isResized.current) return

    if (!breakPoint) {
      setWidth(80)
    }
  }, [width, breakPoint, isResized])

  const { data: authenticatedUser } = useQuery({
    queryKey: ['authenticated-user'],
    queryFn: getAuthenticatedUser,
    staleTime: Infinity,
  })

  const { mutateAsync: signOutFn } = useMutation({
    mutationKey: ['sign-out'],
    mutationFn: signOut,
    onSuccess: () => {
      navigate('/', {
        replace: true,
      })
      queryClient.resetQueries()
    },
  })

  function handleSignOut() {
    signOutFn()
  }

  const navDisplay: {
    icon: JSX.Element
    title: string
    path: string[]
    subpath?: string
    isDropdown?: boolean
    children?: ItemProps[]
    target?: string
  }[] = [
    // {
    //   icon: <BarChart3 />,
    //   title: 'Dashboard',
    //   path: ['/dashboard'],
    //   isDropdown: true,
    //   children: [],
    // },
    {
      icon: <ServerOff />,
      title: 'Incidentes',
      path: ['/incidents'],
      children: [],
    },
    {
      icon: <Layers />,
      title: 'Chamados',
      path: ['/tickets/my'],
    },
    // {
    //   icon: <PieChart />,
    //   title: 'Relatórios',
    //   path: ['/reports'],
    //   isDropdown: true,
    //   children: [
    //     {
    //       icon: <Layers />,
    //       title: 'Chamados',
    //       path: ['/reports', '/tickets'],
    //       isDropdown: false,
    //     },
    //   ],
    // },
  ]

  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles,
  })

  const editModels = roles?.teams.some((team) =>
    team.permissions.some(
      (permission) => permission.name === 'editIncidentModels',
    ),
  )

  const visualizeMap = roles?.teams.some((team) =>
    team.permissions.some((permission) => permission.name === 'visualizeMap'),
  )

  if (visualizeMap) {
    navDisplay[1].isDropdown = true
    navDisplay[1].children = [
      {
        icon: <Layers />,
        title: 'Meus chamados',
        path: ['/tickets/my'],
      },
      {
        icon: <Map />,
        title: 'Maps',
        path: ['/tickets/map'],
      },
    ]
  }

  if (editModels) {
    navDisplay[0].isDropdown = true
    navDisplay[0].children?.push(
      {
        icon: <ServerOff />,
        title: 'Geral',
        path: ['/incidents'],
      },
      {
        icon: <FileBox />,
        title: 'Modelos',
        path: ['/incidents/settings'],
      },
    )
  }

  // const sales = roles?.teams.some((team) =>
  //   team.permissions.some((permission) => permission.name === 'sales'),
  // )

  // const dashboard = navDisplay.find((item) => item.title === 'Dashboard')
  // const reports = navDisplay.find((item) => item.title === 'Relatórios')

  const integrationIXC = roles?.teams.some((team) =>
    team.permissions.some((permission) => permission.name === 'integrationIXC'),
  )

  const statusPage = roles?.teams.some((team) =>
    team.permissions.some((permission) => permission.name === 'statusPage'),
  )

  const stock = roles?.teams.some((team) =>
    team.permissions.some((permission) => permission.name === 'stock'),
  )

  const integrationGoogleSheets = roles?.teams.some((team) =>
    team.permissions.some(
      (permission) => permission.name === 'integrationGoogleSheets',
    ),
  )

  if (integrationIXC || integrationGoogleSheets) {
    navDisplay.splice(2, 0, {
      icon: <Webhook />,
      title: 'Integrações',
      path: ['/integrations'],
      isDropdown: true,
      children: [
        ...(integrationIXC
          ? [
              {
                icon: <IXC />,
                title: 'IXCSoft',
                path: ['/integrations/ixc'],
              },
            ]
          : []),
        ...(integrationGoogleSheets
          ? [
              {
                icon: <Google />,
                title: 'Google Sheets',
                path: ['/integrations/google-sheets'],
              },
            ]
          : []),
      ],
    })
  }

  if (statusPage) {
    navDisplay.splice(3, 0, {
      icon: <ChartSpline />,
      title: 'Status',
      path: ['/services', '/alerts'],
    })
  }

  if (stock) {
    navDisplay.splice(2, 0, {
      icon: <Package />,
      title: 'Estoque',
      path: ['/stock', '/all'],
    })
  }

  // if (sales) {
  //   navDisplay.splice(3, 0, {
  //     icon: <DollarSign />,
  //     title: 'Vendas',
  //     path: ['/sales', '/list'],
  //   })
  //   dashboard?.children?.push({
  //     icon: <DollarSign />,
  //     title: 'Vendas',
  //     path: ['/dashboard', '/sales', '/my'],
  //     isDropdown: false,
  //   })
  //   reports?.children?.push({
  //     icon: <DollarSign />,
  //     title: 'Vendas',
  //     path: ['/reports', '/sales'],
  //     isDropdown: false,
  //   })
  // }

  return (
    <div className="hidden select-none md:flex">
      <div style={{ width: `${width / 16}rem` }}>
        <div className="relative flex min-h-screen w-auto flex-col gap-5 bg-white py-6 dark:bg-gray-950">
          {breakPoint && (
            <>
              <div className="px-5">
                <Raimax />
              </div>
              <Input.Root className="mx-4">
                <Input.Prefix>
                  <Search className="h-5 w-5 text-gray-300 dark:text-gray-375" />
                </Input.Prefix>
                <Input.Control
                  onChange={(e) => setQuery(e.currentTarget.value)}
                  placeholder="Buscar"
                />
              </Input.Root>
            </>
          )}
          <nav className="flex flex-col items-center gap-1 px-4">
            {navDisplay
              .filter((item) => {
                const itemTitleIncludes = item.title
                  .toLowerCase()
                  .includes(query.toLowerCase())

                if (item.children) {
                  return item.children.some((item) =>
                    item.title.toLowerCase().includes(query.toLowerCase()),
                  )
                }

                return itemTitleIncludes
              })
              .map((item) => (
                <Item
                  key={item.path.join('')}
                  icon={item.icon}
                  title={item.title}
                  path={item.path}
                  subpath={item.subpath}
                  isClosed={!breakPoint}
                  isDropdown={item.isDropdown}
                  sub={item.children || []}
                  setWidth={setWidth}
                  target={item.target}
                />
              ))}
          </nav>
          <footer className="mx-4 mt-auto flex flex-col">
            <div className="flex flex-col items-center gap-1 dark:border-gray-750">
              <Item
                icon={<Cog />}
                title="Configurações"
                path={['/settings', '/teams']}
                isClosed={!breakPoint}
              />
              <Tooltip
                side="right"
                description="Sair"
                hidden={breakPoint}
                asChild
              >
                <button
                  onClick={handleSignOut}
                  className={clsx(
                    'group flex w-full items-center gap-2 rounded-md px-3 py-2 font-semibold text-gray-700 transition-colors hover:bg-gray-50 hover:text-gray-900 dark:text-gray-150 dark:hover:bg-gray-750 dark:hover:text-gray-75',
                    {
                      '!w-fit': !breakPoint,
                    },
                  )}
                >
                  <DoorOpen className="h-6 w-6 text-gray-300 transition-colors group-hover:text-gray-700 dark:text-gray-375 dark:group-hover:text-gray-75" />
                  {breakPoint && 'Sair'}
                </button>
              </Tooltip>
            </div>
            {breakPoint && (
              <>
                <div className="mt-6 h-px bg-gray-100 dark:bg-gray-750" />
                <div className="mt-6 w-full">
                  <div
                    className={clsx({
                      'grid grid-cols-[auto,1fr] items-center gap-3':
                        breakPoint,
                    })}
                  >
                    <Tooltip
                      side="right"
                      description={authenticatedUser?.user.name || ''}
                      hidden={breakPoint}
                      asChild
                    >
                      <div
                        className={clsx('h-10 w-10', {
                          'mx-auto': !breakPoint,
                        })}
                      >
                        <Avatar>
                          {abbreviateName(authenticatedUser?.user.name || '')}
                        </Avatar>
                      </div>
                    </Tooltip>

                    <div className="truncate">
                      {authenticatedUser ? (
                        <>
                          <p
                            className="truncate text-sm font-semibold text-gray-700 dark:text-gray-125"
                            title={authenticatedUser?.user.name}
                          >
                            {authenticatedUser?.user.name}
                          </p>
                          <span
                            className="truncate text-sm text-gray-600 dark:text-gray-375"
                            title={authenticatedUser?.user.email}
                          >
                            {authenticatedUser?.user.email}
                          </span>
                        </>
                      ) : (
                        <div className="flex flex-col gap-2">
                          <Skeleton className="h-3.5 w-24 rounded-md" />
                          <Skeleton className="h-3 w-40 rounded-md" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </footer>
        </div>
      </div>
      <div
        className="flex w-4 cursor-col-resize justify-center dark:bg-gray-950"
        onClick={(e) => {
          if (e.detail === 2) {
            setWidth((previousWidth) =>
              previousWidth <= 100 ? maxWidth : minWidth,
            )
          }
        }}
        onMouseDown={() => {
          isResized.current = true
        }}
      >
        <div className="w-px bg-gray-100 dark:bg-gray-750" />
      </div>
    </div>
  )
}
